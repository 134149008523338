/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

:root {
  --color-dark-orange: #fb8500;
  --color-orange: #ffb703;
  --color-dark-blue: #023047;
  --color-light-green: #a9e5b4;
  --color-cool-gray: #636c71;
}

body {
  background: var(--color-dark-blue);
  font-family: "Poppins", sans-serif;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Red Rose", cursive;
}

.white {
  color: white;
}

.center {
  text-align: center;
}

.small {
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  opacity: 0.5;
  margin: 1rem auto;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 18s linear infinite;
  -moz-animation: rotating 18s linear infinite;
  -ms-animation: rotating 18s linear infinite;
  -o-animation: rotating 18s linear infinite;
  animation: rotating 18s linear infinite;
}

.container {
  width: 100%;
}

.title {
  padding-bottom: 0.25rem;
  border-bottom: 2px solid var(--color-light-green);
  margin: 0 auto 2rem;
  width: fit-content;
  letter-spacing: 0.05em;
}

.wrapper {
  background: var(--color-cool-gray);
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
  z-index: 20;
}

.spinner {
  position: absolute;
  display: block;
  width: 6rem;
  height: auto;
  top: -3rem;
  right: -2.5rem;
  z-index: 0;
}

.wrapper::before {
  content: "";
  display: block;
  background: red;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.subtitle {
  font-size: 2rem;
  margin: 0 auto 0.5rem;
}

hr {
  display: block;
  margin: 1rem auto;
  border: none;
  height: 2px;
  width: 4rem;
  background: var(--color-light-green);
}

form {
  display: block;
}

input {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding: 0.5rem;
  border: none;
  box-shadow: inset 0 0 0 0 var(--color-dark-blue);
  width: 100%;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0 1px var(--color-dark-blue);
}

fieldset {
  border: none;
  display: block;
  padding: 0;
  margin-bottom: 1rem;
}

label {
  display: block;
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  color: white;
}

button {
  display: block;
  font-size: 1rem;
  border: none;
  font-family: "Red Rose", cursive;
  width: 100%;
  letter-spacing: 0.05em;
  padding: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  box-shadow: inset 0 0 0 2px var(--color-light-green);
  transition: all 0.15s ease-in-out;
  background: none;
}

button:hover,
button:focus {
  background: var(--color-orange);
  color: var(--color-dark-blue);
  box-shadow: none;
}

.form-message {
  text-align: center;
  color: white;
}

.hide {
  display: none;
}

@media screen and (min-width: 640px) {
  .subtitle {
    font-size: 4rem;
    margin: 0 auto 0.5rem;
  }

  form {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
  }

  button {
    align-self: center;
  }
}
